<template>
  <v-app id="inspire">
    <v-container fluid class="pa-6">
      <!-- Header Section -->
      <v-row class="mb-8">
        <v-col cols="12">
          <div class="d-flex align-center">
            <img height="72" width="72" src="../assets/gamma_logo.svg" class="mr-6" style="object-fit: contain;" />
            <h1 class="text-h3 font-weight-bold mb-1 d-flex align-center">
              Gamma
              <v-chip color="primary" label class="text-caption font-weight-bold ml-4">v2.21</v-chip>
            </h1>
          </div>
        </v-col>
      </v-row>

      <!-- Metrics Grid -->
      <v-row>
        <v-col v-for="(metric, i) in homeMetrics" 
               :key="i" 
               cols="12" 
               sm="6" 
               md="4"
               class="dashboard-metric">
          <v-hover v-slot="{ isHovering }">
            <v-card
              :elevation="isHovering ? 8 : 2"
              :class="['transition-swing', 'metric-card', isHovering ? 'gradient-hover' : '']"
              @click="$router.push(metric.to)"
              height="180"
            >
              <v-card-text>
                <div class="d-flex flex-column h-100">
                  <div class="d-flex justify-space-between align-center mb-4">
                    <v-icon 
                      :class="['metric-icon', isHovering ? 'icon-hover' : '']" 
                      size="48"
                    >{{ metric.icon }}</v-icon>
                    <span class="text-h3 font-weight-bold">{{ metric.metric }}</span>
                  </div>
                  <div class="text-subtitle-1 font-weight-medium">{{ metric.name }}</div>
                  <div class="text-caption text-medium-emphasis mt-auto">
                    Click para ver detalles
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>


    </v-container>
  </v-app>
</template>

<style scoped>
.metric-card {
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(var(--v-border-color), 0.05);
  background: #f8f9fa;
}

.metric-icon {
  color: #2e7d32; /* Material Design Green 800 */
  opacity: 0.9;
  transition: all 0.3s ease;
}

.icon-hover {
  color: white !important;
  transform: scale(1.1);
}

.gradient-hover {
  background: linear-gradient(135deg, #2e7d32, #4caf50); /* Green gradient */
  color: white;
}

.dashboard-metric {
  transition: transform 0.2s ease;
}

.dashboard-metric:hover {
  transform: translateY(-4px);
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'HomePage',
  
  mounted() {
    document.title = "Gamma | Gestión Financiera";
    this.$store.dispatch("getHomepageMetrics");
  },

  computed: {
    ...mapGetters(["homepageMetrics"]),
    homeMetrics() {
      return [
        {
          name: "Campañas",
          to: "/abstract-campaign",
          icon: "mdi-calendar",
          metric: this.homepageMetrics.campaigns,
        },
        {
          name: "Borradores",
          to: "/draft-engagement",
          icon: "mdi-pencil",
          metric: this?.homepageMetrics?.draft_engagements,
        },
        {
          name: "Establecimientos",
          to: "/establishment",
          icon: "mdi-home",
          metric: this?.homepageMetrics?.establishments,
        },
        {
          name: "Insumos/Servicios",
          to: "/cost-items",
          icon: "mdi-crop",
          metric: this?.homepageMetrics?.direct_slice_cost_items,
        },
        {
          name: "Costos Toneladas",
          to: "/other-ton-cost-items",
          icon: "mdi-weight",
          metric: this?.homepageMetrics?.ton_cost_items,
        },
        {
          name: "Politicas Financieras",
          to: "/financial-policy",
          icon: "mdi-file-document",
          metric: this?.homepageMetrics?.financial_policies,
        },
      ];
    },
  },
};
</script>
